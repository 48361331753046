import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Container>
            <p>
                Empty.
            </p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Container className="py-5">
        <ol style={{ listStyle: `none` }}>
            {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
                <li key={post.fields.slug}>
                <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                >
                    <header>
                    <h2>
                        <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                        </Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                    <p
                        dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                    />
                    </section>
                </article>
                </li>
            )
            })}
        </ol>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
